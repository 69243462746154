import React from 'react';
import getTranslations from '../../utils/getTranslations';
import './topQuote.scss';
import quote from '../../../assets/atom/quote.svg';
import avatar from '../../../assets/atom/avatar.svg';

export const TopQuote: React.FC = props => {
  const { jonasQuote, jonasTitle } = getTranslations().about!;
  return (
    <div className='column is-8 is-offset-2 has-text-centered' id='top-quote'>
      <img src={quote} id='quote-marks' />
      <span id='quote'>{jonasQuote}</span>
      <div id='name-and-title-container'>
        <img src={avatar} />
        <div>
          <span id='name'>Jonas West Alrø</span>
          <span id='top-quote-title'>{jonasTitle}</span>
        </div>
      </div>
    </div>
  );
};
