import React from 'react';
import './theTeam.scss';
import { isMobile } from 'react-device-detect';

const TeamImage: React.FC<{
  name: string;
  title: string;
  image: any;
}> = props => {
  return (
    <div
      className={`column is-3 team-image ${isMobile && 'has-text-centered'}`}
      style={{ marginTop: '1rem' }}
    >
      {props.image}
      <span className='member-name'>{props.name}</span>
      <span className='member-title'>{props.title}</span>
    </div>
  );
};

export default TeamImage;
