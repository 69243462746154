import * as React from 'react';
import getTranslations from '../utils/getTranslations';
import { TopQuote } from '../components/about/topQuote';
import { TheTeam } from '../components/about/theTeam';
import { JoinUs } from '../components/about/joinUs';

const AboutPage: React.FC = () => {
  const {} = getTranslations();
  return (
    <div>
      <TopQuote />
      <TheTeam />
      <JoinUs />
    </div>
  );
};

export default AboutPage;
