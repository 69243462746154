import React from 'react';
import { TitleAndSubtitle } from '../shared/titleAndSubtitle';
import getTranslations from '../../utils/getTranslations';
import './joinUs.scss';
import pin from '../../../assets/atom/pin/normal.svg';

const Job: React.FC<{ jobTitle: string; externalUrl?: string }> = props => (
  <div className='column is-6'>
    <div className='level job-container'>
      <div className='level-left'>
        <div className='level-item'>
          <div>
            <a href={props.externalUrl}>
              <span className='job-title'>{props.jobTitle}</span>
            </a>

            <span className='job-location'>
              <img src={pin} />
              Aarhus, Danmark
            </span>
          </div>
        </div>
      </div>
      <div className='level-right'>
        <a href={props.externalUrl} className='level-item button is-rounded'>
          Apply now
        </a>
      </div>
    </div>
  </div>
);

export const JoinUs: React.FC = () => {
  const { joinUs, joinUsSubtitle } = getTranslations().about!;
  return (
    <div id='join-us' className='main-padding'>
      <div id='text'>
        <TitleAndSubtitle title={joinUs!} subtitle={joinUsSubtitle!} />
      </div>
      <div className='columns is-multiline'>
        <Job
          jobTitle='Senior fullstack developer'
          externalUrl={
            'https://eliterekruttering.dk/full-stack-udvikler-til-at-digitalisere-fitnessbranchen/'
          }
        />
      </div>
    </div>
  );
};
