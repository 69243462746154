import React from 'react';
import getTranslations from '../../utils/getTranslations';
import './theTeam.scss';
import { TitleAndSubtitle } from '../shared/titleAndSubtitle';
import Img from 'gatsby-image';
import TeamImage from './teamImage';
import { graphql, StaticQuery } from 'gatsby';

const query = graphql`
  query {
    oscar: file(relativePath: { eq: "oscar.jpg" }) {
      childImageSharp {
        fixed(width: 280, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mikkel: file(relativePath: { eq: "mikkel.jpg" }) {
      childImageSharp {
        fixed(width: 280, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jonas: file(relativePath: { eq: "jonas.jpg" }) {
      childImageSharp {
        fixed(width: 280, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jeppe: file(relativePath: { eq: "jeppe.jpg" }) {
      childImageSharp {
        fixed(width: 280, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    visti: file(relativePath: { eq: "visti.jpg" }) {
      childImageSharp {
        fixed(width: 280, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.jpg" }) {
      childImageSharp {
        fixed(width: 280, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export const TheTeam: React.FC = props => {
  const { theTeam, theTeamSubtitle } = getTranslations().about!;
  return (
    <div id='the-team' className='main-padding'>
      <div id='text'>
        <TitleAndSubtitle title={theTeam!} subtitle={theTeamSubtitle!} />
      </div>

      <StaticQuery
        query={query}
        render={data => {
          return (
            <div className='columns is-multiline'>
              <TeamImage
                name='Jonas West Alrø'
                title='CEO & Co-Founder'
                image={
                  <Img
                    loading='eager'
                    fixed={data.jonas.childImageSharp.fixed}
                  />
                }
              />
              <TeamImage
                name='Mikkel Stampe'
                title='CTO & Co-Founder'
                image={
                  <Img
                    loading='eager'
                    fixed={data.mikkel.childImageSharp.fixed}
                  />
                }
              />
              <TeamImage
                name='Jakob Flarup Øllgaard'
                title='Co-Founder'
                image={
                  <Img
                    loading='eager'
                    fixed={data.placeholder.childImageSharp.fixed}
                  />
                }
              />
              <TeamImage
                name='Jeppe Skødt Benjaminsen'
                title='Application Developer'
                image={
                  <Img
                    loading='eager'
                    fixed={data.jeppe.childImageSharp.fixed}
                  />
                }
              />
              <TeamImage
                name='Visti-Martin Nielsen'
                title='Digital Designer'
                image={
                  <Img
                    loading='eager'
                    fixed={data.visti.childImageSharp.fixed}
                  />
                }
              />
              <TeamImage
                name='Oscar Pradel'
                title='Student worker'
                image={
                  <Img
                    loading='eager'
                    fixed={data.oscar.childImageSharp.fixed}
                  />
                }
              />
              <TeamImage
                name='Victor Fard'
                title='Student worker'
                image={
                  <Img
                    loading='eager'
                    fixed={data.placeholder.childImageSharp.fixed}
                  />
                }
              />
              <TeamImage
                name='Mathias Hammer'
                title='Student worker'
                image={
                  <Img
                    loading='eager'
                    fixed={data.placeholder.childImageSharp.fixed}
                  />
                }
              />
            </div>
          );
        }}
      />
    </div>
  );
};
